<template>
  <div class="cropper-model">
    <el-drawer
      size="60%"
      append-to-body
      :visible.sync="cropperVisible"
      :before-close="handleBeforeClose"
    >
      <!-- <div class="custom-cropper">
        <p>检测到上传的图片格式大小不符合规范，可进行裁剪后进行投放</p>
        <div class="custom-cropper-image-info">
          <div class="clip-image">
            <img class="image" :src="imageUrl" alt="" :style="imageStyle" />
            <div
              class="cropper-image"
              ref="cropperImage"
              :style="cropperImageStyle"
            ></div>
            <div clas="cropper-image-overlay" :style="overlayStyle"></div>
          </div>
          <div class="image-info-detail">
            <p>图片信息</p>
            <p>
              <label> 文件名:&nbsp;{{ fileName }} </label>
            </p>
            <p>
              <label> 原图尺寸: {{ trueWidth }} * {{ trueHeight }} 像素 </label>
            </p>
            <p>
              可选裁切尺寸
            </p>
            <p>
              <label for="">
                750 *
                <el-input-number v-model="cropWidth"></el-input-number> 像素
              </label>
            </p>
            <div class='button-operation'>
              <el-button  @click='handleClose'>取消</el-button>
              <el-button type='primary' @click='handleSure'>保存</el-button>
            </div>
          </div>
        </div>
        <div class="custom-slider">
          <span style="margin-right:5px">缩小</span>
          <el-slider v-model="scaleValue"  :show-tooltip="false"></el-slider>
          <span style="margin-left:5px">放大</span>
        </div>
      </div> -->
      <div class="cropper-row" v-if="isLocalUploadStatus">
        <div class="cropper-box">
          <h2 class="cropper-title"></h2>
          <vueCropper
            class="my-cropper"
            ref="cropper"
            outputType="jpeg"
            maxImgSize="1538"
            :style="myCropperStyle"
            :img="imageUrl"
            :outputSize="1"
            :original="true"
            :hign="false"
            :full="false"
            :infoTrue="false"
            :canMove="true"
            :canMoveBox="true"
            :fixedBox="fixedBox"
            :autoCrop="true"
            :centerBox="true"
            :enlarge="2"
            :autoCropWidth="getCropWidth()"
            :autoCropHeight="getCropHeight()"
            @realTime="onRealTime"
          >
          </vueCropper>
          <div class="crop-preview-box">
            <p>图片信息</p>
            <p>
              <label> 文件名:&nbsp;{{ fileName }} </label>
            </p>
            <p>
              <label> 原图尺寸: {{ trueWidth }} * {{ trueHeight }} 像素 </label>
            </p>
            <p>
              可选裁切尺寸
            </p>
            <div
              class="choose-crop-type"
              v-if="Array.isArray(cropTypeArr) && cropTypeArr.length > 0"
            >
              <span
                @click="chooseType(item, index)"
                v-for="(item, index) in cropTypeArr"
                :key="index"
                :class="{ 'is-selected': curIndex === index }"
                >{{ item.width }} * {{ item.height }}</span
              >
            </div>
            <div class="button-list">
              <el-button size="small" @click="handleClose">取消</el-button>
              <el-button size="small" @click="handleSure" type="primary"
                >完成</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import VueCropper from './vue-cropper'
const PREVIEW_WIDTH = 130
const VIEWPOINT_WIDTH = 280
const MAX_SCALE_VALUE = 0.3733 // 最大放大比例
export default {
  name: 'cropperModel',
  props: [
    'cropperVisible',
    'imageUrl',
    'trueWidth',
    'trueHeight',
    'fileName',
    'needCropWidth',
    'needCropHeight',
    'myCropperWidth',
    'myCropperHeight',
    'fixedBox',
    'cropTypeArr',
    'echoWHObj'
  ],
  data () {
    return {
      curIndex: 0,
      isLocalUploadStatus: false,
      isFirstCreateCropInfo: false, // !是否是首次渲染截图框 用户固定750px宽度
      cropperImageStyle: {}, // 图片编辑框展示区的样式
      imageStyle: {}, // 裁剪区背景图片的样式
      overlayStyle: {}, // 遮罩层的样式
      cropWidth: 100,
      evertStepScaleValue: 0,
      scaleValue: 0, // 倍率
      curScaleNumber: 1, // 图片目前的缩放比例
      scale: 1, //  计算图片的缩小比例
      imageScale: 1, // 图片的缩放比例
      url: '',
      fixedNumber: [
        [2.35, 1],
        [1, 1]
      ],
      activeFixedNumber: [2.35, 1],
      activeType: 0,
      previewStyle1: {
        div: {},
        img: {}
      },
      previewStyle2: {
        div: {},
        img: {}
      },
      preview1: {},
      preview2: {},
      copyNeedCropWidth: 0,
      copyNeedCropHeight: 0,
      isChangeSize: false,
      copyEchoWHObj: {}
    }
  },
  computed: {
    myCropperStyle () {
      return {
        width: `${this.myCropperWidth}px`,
        height: `${this.myCropperHeight}px`
      }
    }
  },
  watch: {
    cropperVisible: {
      handler (newV) {
        if (newV) {
          // this.initData()
          // this.initStyle()
          setTimeout(() => {
            this.isLocalUploadStatus = true
          }, 500)
        }
      }
    },
    scaleValue: {
      handler (newV, oldV) {
        // !防止鼠标快速滑动导致缩放比例不对
        const differ = Math.abs(newV - oldV)
        if (newV > oldV) {
          this.curScaleNumber =
            this.curScaleNumber + this.evertStepScaleValue * differ
        } else {
          this.curScaleNumber =
            this.curScaleNumber - this.evertStepScaleValue * differ
          if (this.curScaleNumber < this.scale) {
            this.curScaleNumber = this.scale
          }
        }
        this.initStyle()
      }
    }
  },
  methods: {
    initData () {
      this.scale = parseFloat(VIEWPOINT_WIDTH / this.trueWidth)
      this.curScaleNumber = this.scale
      // this.currentTarget
      this.evertStepScaleValue = parseFloat(
        ((MAX_SCALE_VALUE - this.scale) / 100).toFixed(4)
      )
    },
    initStyle () {
      this.cropperImageStyle = {
        width: '280px',
        height: `${this.trueHeight * this.scale}px`
      }
      this.imageStyle = {
        position: 'absolute',
        width: `${this.trueWidth}px`,
        height: `${this.trueHeight}px`,
        opacity: 1,
        zIndex: -1,
        top: '50%',
        left: '50%',
        transform: `translate(-50%,-50%) scale(${this.curScaleNumber})`
      }
      this.overlayStyle = {
        width: '280px',
        height: `${this.trueHeight * this.scale}px`,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        border: '2px solid #07c160'
      }
    },
    handleClose () {
      this.isLocalUploadStatus = false
      this.isChangeSize = false
      this.url = ''
      this.curIndex = 0
      this.$emit('close')
    },
    localUpload () {
      // 模拟上传文件的点击事件
      this.$refs.uploadFile.click()
    },
    upload (e) {
      const targetFile = e.target ? e.target.files[0] : e.srcElement.files[0]
      const fr = new FileReader()
      this.$refs.uploadFile.value = ''
      fr.readAsDataURL(targetFile)
      fr.onload = e => {
        this.url = e.currentTarget.result
      }
    },
    // 切换当前裁剪尺寸
    toggleActiveType (type) {
      this.activeType = type
      this.activeFixedNumber = this.fixedNumber[type]
      // 手动触发组件重新渲染(因组件内部无监听比例改变)
      const { cropper } = this.$refs
      if (cropper) {
        this.$nextTick(() => {
          cropper.goAutoCrop()
        })
      }
    },
    // 实时预览
    onRealTime (data) {
      if (!this.activeType) {
        this.previewStyle1 = data
        this.preview1 = {
          width: `${data.w}px`,
          height: `${data.h}px`,
          overflow: 'hidden',
          margin: '0',
          zoom: PREVIEW_WIDTH / data.w
        }
      } else {
        this.previewStyle2 = data
        this.preview2 = {
          width: `${data.w}px`,
          height: `${data.h}px`,
          overflow: 'hidden',
          margin: '0',
          zoom: PREVIEW_WIDTH / data.w
        }
      }
    },
    getCropWidth () {
      const width = this.isChangeSize
        ? this.copyNeedCropWidth
        : this.needCropWidth
      this.copyEchoWHObj.initWidth = width
      return width
    },
    getCropHeight () {
      const height = this.isChangeSize
        ? this.copyNeedCropHeight
        : this.needCropHeight
      this.copyEchoWHObj.initHeight = height
      return height
    },
    handleSure () {
      this.$refs.cropper.getCropBlob(data => {
        const file = new File([data], 'articleImg.jpg', {
          type: data.type,
          lastModified: Date.now()
        })
        if (this.echoWHObj) {
          this.$emit(
            'update:echoWHObj',
            JSON.parse(JSON.stringify(this.copyEchoWHObj))
          )
        }
        this.$emit('cropSuccess', file)
        this.handleClose()
      })
    },
    // 防止出现直接修改父组件传递过来的props导致错误
    handleBeforeClose () {
      this.handleClose()
    },
    chooseType (item, index) {
      this.isChangeSize = true
      this.curIndex = index
      this.copyNeedCropWidth = Number(item.width) / 2
      this.copyNeedCropHeight = Number(item.height) / 2
    }
  },
  components: {
    VueCropper
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-drawer {
  padding:  0 20px;
  // overflow-y: auto;
  overflow: auto;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
  /deep/ #el-drawer__title {
    margin: 0;
    padding: 0;
  }
}
/deep/ .el-slider {
  width: 300px;
}
.is-selected {
  border: 1px solid #1c9399 !important;
  background: #fafefc;
  color: #1c9399;
}

.choose-crop-type {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  color: #b9bbbe;
  > span {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.cropper-model {
  .upload-file {
    display: none;
  }
}
.custom-cropper {
  > p {
    font-size: 13px;
    margin-top: 10px;
  }
  .custom-cropper-image-info {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: move;
    .clip-image {
      position: relative;
      margin-right: 30px;
      width: 372px;
      height: 372px;
      background: rgba(0, 0, 0, 0.8);
      z-index: 1;
      overflow: hidden;
      .image {
        // position: absolute;
        // width:1282px;
        // height:124px;
        // top:50%;
        // left:50%;
        // transform: translate(-50%,-50%) scale(0.218);
      }
      .cropper-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .cropper-image-overlay {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // border:2px solid #07C160;
      }
    }
    .image-info-detail {
      align-self: flex-start;
      font-size: 13px;
      padding-bottom: 10px;
      > p {
        margin: 15px 0;
      }
      > p:nth-of-type(1) {
        color: #a4a5a9;
        border-bottom: 1px solid #ebedf0;
        margin-top: 0px;
        padding-bottom: 10px;
      }
      > p:nth-of-type(3) {
        color: #65676e;
      }
      > p:nth-of-type(4) {
        color: #a4a5a9;
        border-bottom: 1px solid #ebedf0;
        margin-top: 0px;
        padding-bottom: 10px;
      }
    }
  }
  .custom-slider {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 372px;
  }
}
.my-cropper {
  // 等比缩小
  margin-right: 10px;
  // width: 750px;
  // 等比缩小
  // width: 960px;
  // height: 540px;
  // width: 400px;
  // height: 400px;
  text-align: center;
  background-color: white;
  .cropper-crop-box {
    width: 100%;
    height: 100%;
  }
}

.cropper-row {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.cropper-title {
  font-weight: 400;
  text-align: left;
  color: #353535;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
}

.cropper-box {
  padding-right: 10px;
  text-align: center;
  border-right: 1px solid #e7e7eb;
}

.crop-preview {
  line-height: 1.6;

  & > li {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-bottom: 20px;

    &:hover {
      background-color: #f6f8f9;
    }

    & > div {
      margin-left: 14px;
    }
  }

  img {
    width: 130px;
    background-color: #f6f8f9;
    overflow: hidden;
  }

  strong {
    font-weight: 400;
    color: #353535;
  }

  p {
    color: #9a9a9a;
  }
}

.crop-preview-box {
  align-self: flex-start;
  font-size: 13px;
  padding:10px;
  margin-top:20px;
  > p {
    margin: 15px 0;
  }
  > p:nth-of-type(1) {
    color: #a4a5a9;
    border-bottom: 1px solid #ebedf0;
    margin-top: 0px;
    padding-bottom: 10px;
  }
  > p:nth-of-type(3) {
    color: #65676e;
  }
  > p:nth-of-type(4) {
    color: #a4a5a9;
    border-bottom: 1px solid #ebedf0;
    margin-top: 0px;
    padding-bottom: 10px;
  }
}

.crop-preview-wrapper1 {
  width: 130px;
  height: calc(55.3191px);
  overflow: hidden;
}

.crop-preview-wrapper {
  width: 130px;
  height: 130px;
  margin-right: 14px;
  overflow: hidden;
}

.ratio {
  padding-top: 20px;
  text-align: left;

  p {
    border: 1px solid #e4e8eb;
    display: inline-block;
    border-radius: 3px;
    padding: 5px 0;
  }

  span {
    cursor: pointer;
    position: relative;
    background: none;
    padding: 0 10px;
    border-color: #e4e8eb;
    border-right: none;
    border-left: none;
    color: #9a9a9a;
    min-width: 60px;
    text-align: center;
    display: inline-block;
    box-sizing: border-box;

    &:first-child {
      border-right: 1px solid #e4e8eb;
    }
  }

  .active {
    color: #07c160;
  }
}

.button-list {
  text-align: center;
  padding: 24px 32px;
}
</style>
